import React, {useState, useMemo, useEffect} from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Button from '../buttons/button';
import Banner from "../../imgs/bg_buniversity.jpg";
import Pagination from '../pagination/pagination';
import {Link} from "gatsby";
import DateSelect from "../dateSelect/dateSelect";
import './Glosario.css'
import styles from './BalanzUniversity.css'
import { apiRequest } from "../../apiRquest/apiRequest";
import axios from "axios";
import BannerFondo from "../../imgs/banner_BU_glosario.png";
import { ButtonList } from "../buttons_list/buttonList";
import { SalWrapper } from "react-sal"


const GlosarieList = () => {
    const [wordsA, setWordsA] = useState([]);
    const [wordsB, setWordsB] = useState([]);
    const [wordsC, setWordsC] = useState([]);
    const [wordsD, setWordsD] = useState([]);
    const [wordsE, setWordsE] = useState([]);
    const [wordsF, setWordsF] = useState([]);
    const [wordsG, setWordsG] = useState([]);
    const [wordsH, setWordsH] = useState([]);
    const [wordsI, setWordsI] = useState([]);
    const [wordsJ, setWordsJ] = useState([]);
    const [wordsK, setWordsK] = useState([]);
    const [wordsL, setWordsL] = useState([]);
    const [wordsM, setWordsM] = useState([]);
    const [wordsN, setWordsN] = useState([]);
    const [wordsO, setWordsO] = useState([]);
    const [wordsP, setWordsP] = useState([]);
    const [wordsQ, setWordsQ] = useState([]);
    const [wordsR, setWordsR] = useState([]);
    const [wordsS, setWordsS] = useState([]);
    const [wordsT, setWordsT] = useState([]);
    const [wordsU, setWordsU] = useState([]);
    const [wordsV, setWordsV] = useState([]);
    const [wordsW, setWordsW] = useState([]);
    const [wordsX, setWordsX] = useState([]);
    const [wordsY, setWordsY] = useState([]);
    const [wordsZ, setWordsZ] = useState([]);
    useEffect(() => {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      
        const fetchData = async () => {
          try {
            const response = await apiRequest.post("/v1/glossaries/letras/", {
              letra: alphabet,
            });
            const dataWords = {};
      
            alphabet.split("").forEach((letra) => {
              dataWords[letra] = response.data
                .filter((item) => item.letra === letra)
                .map((item) => ({
                  id: item.id,
                  title: item.title,
                  metatitle: item.metatitle,
                  slug: item.slug,
                  description: item.description,
                  htmlcontent: item.htmlcontent,
                  htmldesc: item.htmldesc,
                  status: item.status,
                  letra: item.letra,
                }));
            });

                setWordsA(dataWords .A);
                setWordsB(dataWords .B);
                setWordsC(dataWords .C);
                setWordsD(dataWords .D);
                setWordsE(dataWords .E);
                setWordsF(dataWords .F);
                setWordsG(dataWords .G);
                setWordsH(dataWords .H);
                setWordsI(dataWords .I);
                setWordsJ(dataWords .J);
                setWordsK(dataWords .K);
                setWordsL(dataWords .L);
                setWordsM(dataWords .M);
                setWordsN(dataWords .N);
                setWordsO(dataWords .O);
                setWordsP(dataWords .P);
                setWordsQ(dataWords .Q);
                setWordsR(dataWords .R);
                setWordsS(dataWords .S);
                setWordsT(dataWords .T);
                setWordsU(dataWords .U);
                setWordsV(dataWords .V);
                setWordsW(dataWords .W);
                setWordsX(dataWords .X);
                setWordsY(dataWords .Y);
                setWordsZ(dataWords .Z);
              
            } catch (error) {
              throw error;
            }
          
        };
      
        fetchData();
      }, []);
  
  function showWords (sBookmark, bUseHash) {
    if (arguments.length === 1 || bUseHash)
     { window.location.hash = sBookmark; return; }
    var oBookmark = document.querySelector(sBookmark);
    
  }


    return (
        <>
        
        <section className="fullwidth pb-0">
       
          <div className="lightgrey pb-4 pb-sm-5">
          <Container className="d-flex align-items-center justify-content-center "  style={{ padding: 0}}>
                  <Row 
                  >
                      <Col xs={12} md={12}>
                        <Nav>

                        {wordsA.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('A')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                A 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    A 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsB.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('B')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                B 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    B 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
           
                            {wordsC.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('C')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                C 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    C 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                        
                            {wordsD.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('D')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                D 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    D 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsE.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('E')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                E 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    E 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsF.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('F')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                F 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    F 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }


                            {wordsG.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('G')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                G 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    G 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsH.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('H')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                H 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    H 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsI.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('I')}}className="link-nav-letra">
                                            <span className="secondary-color">
                                                I 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    I 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsJ.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('J')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                J 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    J 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsK.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('K')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                K 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    K 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsL.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('L')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                L 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    L 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                            {wordsM.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('M')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                M 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    M 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsN.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('N')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                N 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    N 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsO.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('O')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                O 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    O 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsP.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('P')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                P 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    P 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsQ.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('Q')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                Q
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    Q 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsR.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('R')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                R 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    R 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsS.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('S')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                S 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    S 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsT.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('T')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                T 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    T 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsU.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('U')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                U 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    U 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsV.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('V')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                V 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    V 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsW.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('W')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                W 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    W 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsX.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('X')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                X 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    X
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsY.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('Y')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                Y 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    Y 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }
                            
                            {wordsZ.length > 0 ? 
                                (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link onClick={() => {showWords('Z')}} className="link-nav-letra">
                                            <span className="secondary-color">
                                                Z 
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                            
                                    </>
                                ) : (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link className="link-nav-letra">
                                                <span className="grey-color">
                                                    Z 
                                                </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    </>
                                )
                            }

                        </Nav>
                      </Col>
                  </Row>
              </Container>
              
          </div>
      </section>




      { wordsA.length > 0 &&
           <section className="fullwidth asesor" id="A">
           <Container>
           <Row className="div-border-top pb-5">  
                 <Col xs={6} md={4} >
                             <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}} className="d-flex justify-content-start align-self-start">A</p>
                         </Col>
                         <Row style={{ width: 800, padding:0 }} >               
                                       {
                                       wordsA.map((item, index) => {
                                           return (     
                                                    <Col  key={index} md={4} >
                                                   {
                                                       <>
                                                       <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                       <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                       </Link>
                                                       </>
                                                   }
                                                   </Col>
                                               );
                                           })
                                       }
                            
                         </Row>
                 </Row>
           </Container>
       </section>
        }

        { wordsB.length > 0 &&
                <section className="fullwidth asesor" id="B">
                <Container>
                <Row  className="div-border-top pb-5">  
                        <Col xs={6} md={4} >
                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >B</p>
                                </Col>
                                <Row style={{ width: 800, padding:0 }}>               
                                            {
                                            wordsB.map((item, index) => {
                                                return (     
                                                            <Col  key={index} md={4} >
                                                        {
                                                            <>
                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                            </Link>
                                                            </>
                                                        }
                                                        </Col>
                                                    );
                                                })
                                            }
                                    
                                </Row>
                        </Row>
                </Container>
            </section>
                }



        <section className="fullwidthbglosario glosario1 py-4 ">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={8}>
                            <h3 className="titulo banner-glosaries me-3 me-md-3 me-lg-4 me-xl-5"  style={{ color:'#FFFFFF' }} >
                            <span className="highlightsbold">
                                $0 costo de apertura y de mantenimiento.
                                </span>
                            <span className="highlightsbold">
                                 Sin mínimo de inversión. 
                                </span>
                            <span className="bigger-title-glosario">
                                Transformá tu ahorro en inversión.
                            </span>
                            </h3>
                        
                    </Col>
                    <Col xs={12} md={2}  className="d-flex align-self-center">

                                <a className="btn btn-secondary" href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399">
                                    Abrir Cuenta
                                </a>
                        </Col>

                  </Row>
            </Container>
        </section>


        { wordsC.length > 0 &&
           <section className="fullwidth asesor" id="C">
           <Container>
           <Row  className="div-border-top  pb-5">  
                 <Col xs={6} md={4} >
                             <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >C</p>
                         </Col>
                         <Row style={{ width: 800, padding:0 }}>               
                                       {
                                       wordsC.map((item, index) => {
                                           return (     
                                                    <Col  key={index} md={4} >
                                                   {
                                                       <>
                                                       <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                       <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                       </Link>
                                                       </>
                                                   }
                                                   </Col>
                                               );
                                           })
                                       }
                            
                         </Row>
                 </Row>
           </Container>
       </section>
        }


{ wordsD.length > 0 &&
           <section className="fullwidth asesor" id="D">
           <Container>
           <Row  className="div-border-top  pb-5">  
                 <Col xs={6} md={4} >
                             <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >D</p>
                         </Col>
                         <Row style={{ width: 800, padding:0 }}>               
                                       {
                                       wordsD.map((item, index) => {
                                           return (     
                                                    <Col  key={index} md={4} >
                                                   {
                                                       <>
                                                       <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                       <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                       </Link>
                                                       </>
                                                   }
                                                   </Col>
                                               );
                                           })
                                       }
                            
                         </Row>
                 </Row>
           </Container>
       </section>
        }


        { wordsE.length > 0 &&
                <section className="fullwidth asesor" id="E">
                <Container>
                <Row className="div-border-top  pb-5">  
                        <Col xs={6} md={4} >
                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >E</p>
                                </Col>
                                <Row style={{ width: 800, padding:0 }}>               
                                            {
                                            wordsE.map((item, index) => {
                                                return (     
                                                            <Col  key={index} md={4} >
                                                        {
                                                            <>
                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                            </Link>
                                                            </>
                                                        }
                                                        </Col>
                                                    );
                                                })
                                            }
                                    
                                </Row>
                        </Row>
                </Container>
            </section>
                }


        { wordsF.length > 0 &&
                <section className="fullwidth asesor" id="F">
                <Container>
                <Row  className="div-border-top  pb-5">  
                        <Col xs={6} md={4} >
                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >F</p>
                                </Col>
                                <Row style={{ width: 800, padding:0 }}>               
                                            {
                                            wordsF.map((item, index) => {
                                                return (     
                                                            <Col  key={index} md={4} >
                                                        {
                                                            <>
                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                            </Link>
                                                            </>
                                                        }
                                                        </Col>
                                                    );
                                                })
                                            }
                                    
                                </Row>
                        </Row>
                </Container>
            </section>
                }


        { wordsG.length > 0 &&
                <section className="fullwidth asesor" id="G">
                <Container>
                <Row  className="div-border-top  pb-5">  
                        <Col xs={6} md={4} >
                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >G</p>
                                </Col>
                                <Row style={{ width: 800, padding:0 }}>               
                                            {
                                            wordsG.map((item, index) => {
                                                return (     
                                                            <Col  key={index} md={4} >
                                                        {
                                                            <>
                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                            </Link>
                                                            </>
                                                        }
                                                        </Col>
                                                    );
                                                })
                                            }
                                    
                                </Row>
                        </Row>
                </Container>
            </section>
                }

            { wordsH.length > 0 &&
                    <section className="fullwidth asesor" id="H">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >H</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsH.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


            { wordsI.length > 0 &&
                                <section className="fullwidth asesor" id="I">
                                <Container>
                                <Row  className="div-border-top  pb-5">  
                                        <Col xs={6} md={4} >
                                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >I</p>
                                                </Col>
                                                <Row style={{ width: 800, padding:0 }}>               
                                                            {
                                                            wordsI.map((item, index) => {
                                                                return (     
                                                                            <Col  key={index} md={4} >
                                                                        {
                                                                            <>
                                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                            </Link>
                                                                            </>
                                                                        }
                                                                        </Col>
                                                                    );
                                                                })
                                                            }
                                                    
                                                </Row>
                                        </Row>
                                </Container>
                            </section>
                                }
                                

            { wordsJ.length > 0 &&
                    <section className="fullwidth asesor" id="J">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >J</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsJ.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


            { wordsK.length > 0 &&
                                <section className="fullwidth asesor" id="K">
                                <Container>
                                <Row  className="div-border-top  pb-5">  
                                        <Col xs={6} md={4} >
                                                    <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >K</p>
                                                </Col>
                                                <Row style={{ width: 800, padding:0 }}>               
                                                            {
                                                            wordsK.map((item, index) => {
                                                                return (     
                                                                            <Col  key={index} md={4} >
                                                                        {
                                                                            <>
                                                                            <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                            <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                            </Link>
                                                                            </>
                                                                        }
                                                                        </Col>
                                                                    );
                                                                })
                                                            }
                                                    
                                                </Row>
                                        </Row>
                                </Container>
                            </section>
                                }

        
                { wordsL.length > 0 &&
                    <section className="fullwidth asesor" id="L">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >L</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsL.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


            { wordsM.length > 0 &&
                    <section className="fullwidth asesor" id="M">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >M</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsM.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsN.length > 0 &&
                    <section className="fullwidth asesor" id="N">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >N</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsN.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsP.length > 0 &&
                    <section className="fullwidth asesor" id="P">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >P</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsP.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }

                { wordsQ.length > 0 &&
                    <section className="fullwidth asesor" id="Q">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >Q</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsQ.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsR.length > 0 &&
                    <section className="fullwidth asesor" id="R">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >R</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsR.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsS.length > 0 &&
                    <section className="fullwidth asesor" id="S">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >S</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsS.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


       
            { wordsT.length > 0 &&
                    <section className="fullwidth asesor" id="T">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >T</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsT.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsU.length > 0 &&
                    <section className="fullwidth asesor" id="U">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >U</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsU.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsV.length > 0 &&
                    <section className="fullwidth asesor" id="V">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >V</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsV.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsW.length > 0 &&
                    <section className="fullwidth asesor" id="W">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >W</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsW.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }



                { wordsX.length > 0 &&
                    <section className="fullwidth asesor" id="X">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >X</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsX.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsY.length > 0 &&
                    <section className="fullwidth asesor" id="Y">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >Y</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsY.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }


                { wordsZ.length > 0 &&
                    <section className="fullwidth asesor" id="Z">
                    <Container>
                    <Row  className="div-border-top  pb-5">  
                            <Col xs={6} md={4} >
                                        <p style={{ fontSize: '110px', color:'#00A3E4', lineHeight: 1}}  className="d-flex justify-content-start align-self-start" >Z</p>
                                    </Col>
                                    <Row style={{ width: 800, padding:0 }}>               
                                                {
                                                wordsZ.map((item, index) => {
                                                    return (     
                                                                <Col  key={index} md={4} >
                                                            {
                                                                <>
                                                                <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>                                                    
                                                                <p style={{fontSize: '20px',   color:'#0D2055'}}>{item.title}</p>  
                                                                </Link>
                                                                </>
                                                            }
                                                            </Col>
                                                        );
                                                    })
                                                }
                                        
                                    </Row>
                            </Row>
                    </Container>
                </section>
                    }

<section className="fullwidthbg-glo-cursos banner-bu-glosario">
            <Container className="d-flex align-items-center">
                  <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5">
                    <Col xs={12} md={8}>
                            <div className="border-banner">
                            <h4 className="titulo me-3 me-md-3 me-lg-4 me-xl-5"  style={{ color:'#0D2055' }}><span style={{fontWeight: "300"}}>Aprendé todo lo que tenés que saber para invertir mejor y potenciar tus ahorros con nuestros cursos.</span></h4>
                            <Button variant="btn btn-secondary" text="Ver cursos" onClick={() => { window.open("https://balanzuniversity.com/", "_blank") }} />
                    
                    </div>
                    </Col>
                    <Col xs={12} md={2}>
                          
                    </Col>
                  </Row>
            </Container>
        </section>

            </>
       
    );
}

export default GlosarieList;